import { useProfiles, useUsers } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { SessionStorageKeys, useSessionStorageTyped } from 'hooks';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

import { useAuth } from '@guider-global/auth-hooks';
import { datadogLogs } from '@guider-global/datadog';
import { useOrganization } from '@guider-global/sanity-hooks';
import { URLQueryParams } from '@guider-global/shared-types';
import { LoadingElement } from 'components';
import { getIncompleteProfileFields } from 'utils';
let { version: REACT_APP_VERSION } = require('../../../package.json');

export interface AuthenticatedRouteWrapperProps {
  element: ReactNode;
}
export const AuthenticatedWrapper: FC<AuthenticatedRouteWrapperProps> = ({
  element,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // Local State
  const [isError, setIsError] = useState<boolean>(false);

  // Auth
  const { isLoading: loadingAuth, isAuthenticated } = useAuth({});
  // Redux
  const dispatch = useAppDispatch();
  //Intercom
  const { update: updateIntercom } = useIntercom();
  //Utils
  const organizationSlug = getSubDomain();

  // Hooks
  // - useSessionStorage
  const [sessionRedirect] = useSessionStorageTyped<string>(
    SessionStorageKeys.REGISTER_REDIRECT,
  );
  // -Users
  const { users, isLoadingUsers, isValidatingUsers, errorsUsers } = useUsers(
    {},
  );
  const user = users?.at(0);

  // -Profiles
  const { isLoadingProfiles, isValidatingProfiles, profile, errorsProfiles } =
    useProfiles({});

  // - Sanity organization
  const { organization: sanityOrganization } = useOrganization({
    organizationSlug,
  });

  const additionalAgreements =
    sanityOrganization?.white_label?.additional_agreements;

  const isLoading = loadingAuth || isLoadingProfiles || isLoadingUsers;
  const isValidating = isValidatingUsers || isValidatingProfiles;

  const incompleteProfileFields =
    profile && sanityOrganization
      ? getIncompleteProfileFields({
          profile,
          sanityOrganization,
          organizationAgreements: additionalAgreements,
        })
      : [];

  useEffect(() => {
    if (isError) return;
    const currentUrl = new URL(window.location.href);
    const navigateToDifferentPath = (pathName: string) => {
      if (currentUrl.pathname !== pathName) {
        navigate(pathName);
      }
    };

    if (isLoading || isValidating) return;
    if (!isAuthenticated) {
      const locationPathname = location.pathname;
      const isGuideOrTraineeProgramRegistrationPage =
        locationPathname.endsWith('/join/guide') ||
        locationPathname.endsWith('/join/trainee');
      const navigationAddress = isGuideOrTraineeProgramRegistrationPage
        ? '/register/account'
        : '/login';
      const to = locationPathname
        ? `${navigationAddress}?${URLQueryParams.REDIRECT}=${encodeURIComponent(
            locationPathname,
          )}`
        : navigationAddress;
      navigate(to);
      return;
    }
    if (!profile || !users) return;
    updateIntercom({
      name: profile?.displayName,
      email: user?.email,
      userId: profile?.userId,
      createdAt: user?.createdAt?.toString(),
      customAttributes: {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        organizationSlug,
        appVersion: REACT_APP_VERSION,
      },
    });
    if (!profile || !profile.isOnboarded) {
      navigateToDifferentPath('/register');

      return;
    }
    if (incompleteProfileFields.length > 0) {
      navigateToDifferentPath('/complete-profile');

      return;
    }
    if (sessionRedirect) {
      sessionStorage.removeItem(SessionStorageKeys.REGISTER_REDIRECT);
      window.history.pushState({}, '', `${origin}/programs`);
      try {
        const fullUrl = new URL(sessionRedirect);
        if (fullUrl) {
          if (currentUrl.href !== fullUrl.href) {
            window.location.href = fullUrl.href;
          }
        }

        return;
      } catch (_error) {
        navigateToDifferentPath(sessionRedirect);

        return;
      }
    }
  }, [
    incompleteProfileFields.length,
    isAuthenticated,
    isError,
    isLoading,
    isValidating,
    location.pathname,
    navigate,
    organizationSlug,
    profile,
    sessionRedirect,
    updateIntercom,
    user?.createdAt,
    user?.email,
    users,
  ]);

  useEffect(() => {
    if (isError) return;

    const handleShowAppError = (errorCode?: string, errorMessage?: string) => {
      console.error('error', { errorCode, errorMessage });
      datadogLogs.logger.error('authenticateWrapper:', {
        errorCode,
        errorMessage,
      });
      setIsError(true);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: errorMessage,
          timeout: 10000,
        }),
      );
    };

    if (errorsProfiles) {
      handleShowAppError(errorsProfiles?.code, errorsProfiles?.message);
    }
    if (errorsUsers) {
      handleShowAppError(errorsUsers.code, errorsUsers.message);
    }
  }, [dispatch, errorsProfiles, errorsUsers, isError]);

  if (isLoading) return <LoadingElement timeout={1000} />;

  return element;
};
